import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '目录',
      pos:0,
    },

    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    meta: {
      title: '简介',
      pos:0,
    },
    
    component: () => import('../views/detail.vue')
  },
  {
    path: '/farming',
    name: 'Farming',
    meta: {
      pos:1,
    },
    props: { routeName: 'Farming' },
    component: () => import('../views/Farming.vue')
  },
  {
    path: '/marketplace',
    name: 'Marketplace',
    meta: {
      pos:2,
    },
    props: { routeName: 'Marketplace' },
    component: () => import('../views/Marketplace.vue'),
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      pos:3,
    },
    props: { routeName: 'About' },
    component: () => import('../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
